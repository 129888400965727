"use client";

import { Suspense, useEffect } from "react";
import { useRouter } from "next/navigation";
import { useAtom } from "jotai";
import { accessTokenAtom } from "@/store/global";
import { setTokens, getAccessToken, refreshAccessToken } from "@/utils/token";

interface ClientWrapperProps {
  children: React.ReactNode;
}

export default function ClientWrapper({ children }: ClientWrapperProps) {
  const [, setAuthToken] = useAtom(accessTokenAtom);
  const router = useRouter();

  useEffect(() => {
    const handleTokens = async () => {
      // URL 파라미터 읽기
      const urlParams = new URLSearchParams(window.location.search);
      const tokenFromUrl = urlParams.get("access_token");
      const refreshTokenFromUrl = urlParams.get("refresh_token");

      if (tokenFromUrl && refreshTokenFromUrl) {
        // URL에서 토큰을 받았을 때
        setTokens(tokenFromUrl, refreshTokenFromUrl);
        setAuthToken(tokenFromUrl);

        // URL에서 토큰 파라미터 제거
        urlParams.delete("access_token");
        urlParams.delete("refresh_token");
        const newUrl =
          window.location.pathname +
          (urlParams.toString() ? `?${urlParams.toString()}` : "");
        router.replace(newUrl);
      } else {
        // 쿠키에서 토큰 읽기
        const tokenFromCookie = getAccessToken();
        if (tokenFromCookie) {
          setAuthToken(tokenFromCookie);
        } else {
          // 액세스 토큰이 없으면 리프레시 토큰으로 갱신 시도
          const newAccessToken = await refreshAccessToken();
          if (newAccessToken) {
            setAuthToken(newAccessToken);
          } else {
            // 리프레시도 실패하면 로그인 페이지로 리다이렉트
            // router.push("/login");
          }
        }
      }
    };

    handleTokens();
  }, [router, setAuthToken]);

  return <Suspense>{children}</Suspense>;
}
