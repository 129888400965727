import Cookies from "js-cookie";
import { NextRequest, NextResponse } from "next/server";
import {getLocalStorage} from "@/utils/storage";

interface CookieOptions {
  path?: string;
  expires?: number | Date;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
}

const ACCESS_TOKEN_KEY = "access_token";
const REFRESH_TOKEN_KEY = "refresh_token";

/**
 * 도메인 변환 함수
 */
const convertDomain = (req?: NextRequest): string => {
  if (typeof window !== "undefined") {
    return window.location.hostname;
  } else if (req) {
    return req.headers.get("host")?.split(":")[0] || "";
  } else {
    return "";
  }
};

/**
 * 쿠키 설정 함수
 */
const setCookie = (
  key: string,
  value: string,
  options: CookieOptions,
  res?: NextResponse,
) => {
  if (typeof window !== "undefined") {
    Cookies.set(key, value, options);
  } else if (res) {
    res.cookies.set(key, value, options);
  }
};

/**
 * 쿠키 가져오기 함수
 */
const getCookie = (key: string, req?: NextRequest): string | undefined => {
  if (typeof window !== "undefined") {
    return Cookies.get(key);
  } else if (req) {
    return req.cookies.get(key)?.value;
  }
};

/**
 * 액세스 토큰 & 리프레시 토큰 저장하기
 */
export const setTokens = (
  accessToken: string,
  refreshToken: string,
  req?: NextRequest,
  res?: NextResponse,
) => {
  const isProduction = process.env.NODE_ENV === "production";
  const domain = isProduction ? convertDomain(req) : undefined;

  const commonOptions: CookieOptions = {
    path: "/",
    secure: isProduction,
    domain,
  };

  setCookie(
    ACCESS_TOKEN_KEY,
    accessToken,
    {
      ...commonOptions,
      expires: new Date(Date.now() + 24 * 60 * 60 * 1000), // 1일
    },
    res,
  );

  setCookie(
    REFRESH_TOKEN_KEY,
    refreshToken,
    {
      ...commonOptions,
      expires: new Date(Date.now() + 90 * 24 * 60 * 60 * 1000), // 90일
    },
    res,
  );
};

/**
 * 액세스 토큰 가져오기
 */
export const getAccessToken = (req?: NextRequest): string | undefined => {
  if (typeof window !== 'undefined') {
    // Client-side
    const token = Cookies.get(ACCESS_TOKEN_KEY);
    console.log("AA", token);
    return token;
  } else if (req) {
    // Server-side
    return req.cookies.get(ACCESS_TOKEN_KEY)?.value;
  }
};

/**
 * 리프레시 토큰으로 액세스 토큰 갱신하기
 */
export const refreshAccessToken = async (
  req?: NextRequest,
): Promise<string | undefined> => {
  const refreshToken = getCookie(REFRESH_TOKEN_KEY, req);

  if (!refreshToken) {
    return undefined;
  }

  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_DOMAIN}/auth/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Cache-Control': 'no-store',
        },
        body: JSON.stringify({ refresh_token: refreshToken }),
      },
    );

    if (!response.ok) {
      throw new Error("Failed to refresh token");
    }

    const data = await response.json();
    const newAccessToken = data.access_token;

    // 여기서는 res 객체가 없으므로, 클라이언트 사이드에서만 토큰을 설정합니다.
    // 서버 사이드에서 호출할 경우, 반환된 newAccessToken을 사용하여 별도로 처리
    if (typeof window !== "undefined") {
      setTokens(newAccessToken, refreshToken);
    }

    return newAccessToken;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    return undefined;
  }
};

/**
 * API 호출을 위한 헤더 생성 함수
 */
export const createAuthHeader = (accessToken?: string): HeadersInit => {
  return {
    Authorization: accessToken ? `Bearer ${accessToken}` : "",
    "Content-Type": "application/json",
  };
};

/**
 * 인증이 필요한 API 호출 함수
 */
export const fetchWithAuth = async (
  url: string,
  options: RequestInit = {},
  req?: NextRequest
): Promise<Response> => {
  const accessToken = getAccessToken(req);

  if (!accessToken) {
    console.error("No access token available");
    throw new Error("No access token available");
  }

  const headers = new Headers(options.headers || {});
  headers.set('Authorization', `Bearer ${accessToken}`);

  // Don't set Content-Type if it's FormData
  if (!(options.body instanceof FormData)) {
    headers.set('Content-Type', 'application/json');
  }

  return fetch(url, {
    ...options,
    cache: 'no-store',
    headers,
  });
};

/**
 * 쿠키 삭제 함수
 */
const removeCookie = (
  key: string,
  options: CookieOptions,
  res?: NextResponse,
) => {
  if (typeof window !== "undefined") {
    Cookies.remove(key, options);
  } else if (res) {
    res.cookies.delete(key);
  }
};

/**
 * 로그아웃 - 액세스 토큰 & 리프레시 토큰 삭제하기
 */
export const logout = (req?: NextRequest, res?: NextResponse) => {
  const isProduction = process.env.NODE_ENV === "production";
  const domain = isProduction ? convertDomain(req) : undefined;

  const commonOptions: CookieOptions = {
    path: "/",
    domain,
  };

  removeCookie(ACCESS_TOKEN_KEY, commonOptions, res);
  removeCookie(REFRESH_TOKEN_KEY, commonOptions, res);
};